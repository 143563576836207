import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import { Layout } from "../components";
import { MetaData } from "../components/common/meta";

/**
 * Topics page (/topics)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Topics = ({ data, location }) => {
  const tags = data.allGhostTag.edges;
  return (
    <>
      <MetaData title="Topics" data={data} location={location} />
      <Layout title="Topics">
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
          <h1 className="uppercase">Topics</h1>
          <ul className="list-none space-y-2">
            {tags.map(({ node }, i) => (
              <li key={`topic-p-${i}`}>
                <Link className="link" to={`/topics/${node.slug}`}>
                  {node.name} ({node.postCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    </>
  );
};

Topics.propTypes = {
  data: PropTypes.shape({
    allGhostTag: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query TopicsPageQuery {
    allGhostTag(sort: { order: ASC, fields: name }) {
      edges {
        node {
          slug
          name
          postCount
        }
      }
    }
  }
`;

export default Topics;
